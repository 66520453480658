.ant-dropdown-menu {
  padding: 6px 2px;
  border-radius: 6px;

  &-item {
    height: 32px;
    align-items: center;
    padding: 0 8px;

    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.46px;
    font-weight: 500;
    color: #141414;

    &:hover {
      background-color: #f3f3f3;
    }

    &-selected {
      background-color: #fff;
    }

    span {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
