/* You can add global styles to this file, and also import other style files */
@import 'boxicons';
@import 'video.js/dist/video-js.css';
@import './styles/ui/dropdown';
@import './styles/ui/select';
@import './styles/ui/checkbox';

* {
  box-sizing: border-box;
}

.splide {
  .splide__arrow.splide__arrow--prev {
    background-color: transparent;
    left: -40px;
  }

  .splide__arrow.splide__arrow--next {
    background-color: transparent;
    right: -40px;
  }

  .splide__pagination {
    bottom: -25px !important;
    display: flex;
    gap: 6px;

    .splide__pagination__page {
      width: 30px;
      height: 3px;
      background: rgba(20, 20, 20, 0.2);
      border-radius: 66px;
    }

    .splide__pagination__page.is-active {
      transform: scale(1);
      background: #141414;
    }
  }
}

@media (max-width: 576px) {
  .splide {
    .splide__pagination {
      display: none;
    }
  }
}
