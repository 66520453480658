.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #141414;
  border-color: #141414;
  box-shadow: 0px 2px 4px 0px #00000033;
}

.ant-checkbox-checked::after {
  border-color: #141414;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #141414;
}

.ant-checkbox-input:focus + .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
  border-color: #141414;
}

.ant-checkbox-inner {
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border-color: #D4D9DE;
}

.ant-checkbox-inner::after {
  left: 22.5%;
  top: 48%;
}

.ant-checkbox+span {
  padding: 0 0 0 7px;
  font-size: 16px;
  line-height: 18px;
}
