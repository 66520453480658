.ant-select {
  width: 100%;
  font-size: 15px;
  display: block;

  &.ng-touched.ng-invalid {
    .ant-select-selector {
      border-color: #dc3545 !important;
    }
  }

  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #004851;
    box-shadow: none;
    padding: 0 12px 0 15px;
  }

  &:not(.ant-select-customize-input) .ant-select-selector {
    height: 38px;
    border-radius: 5px;
    border: 1px solid #d4d9de;
    padding: 0 12px 0 15px;

    .ant-select-selection-search-input,
    .ant-select-selection-placeholder {
      height: 36px;
      line-height: 36px;
    }

    .ant-select-selection-item {
      line-height: 36px;
    }
  }

  & .ant-select-selector .ant-select-selection-item {
    height: 38px;
  }

  .ant-select-clear {
    margin-top: -8px;
  }

  .ant-select-arrow {
    margin-top: -5px;
  }

  &.braathe-page-changer {
    min-width: 72px;

    &:not(.ant-select-customize-input) .ant-select-selector {
      height: 34px;

      .ant-select-selection-search-input,
      .ant-select-selection-placeholder {
        height: 32px;
        line-height: 32px;
      }

      .ant-select-selection-item {
        line-height: 22px;
        height: 24px;
      }
    }
  }
}

.ant-select-dropdown {
  border-radius: 5px;
  padding: 6px 5px 8px;

  .ant-select-item-option {
    border-radius: 2px;

    &.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background: #004851;
      font-weight: 500;
      color: #fff;
    }
  }
}

.multiple-select-dropdown .ant-select-dropdown {
  .ant-select-item-option.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: #119a481a;
    color: #000000d9;
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
    color: #004851;
    display: flex;
    align-items: center;
  }

   .ant-select-selection-item {
    line-height: 22px;
  }

   .ant-select-selection-item {
    height: 24px;
  }
}

.ant-select-multiple .ant-select-selection-item {
  height: 24px !important;
  line-height: 22px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #004851;
}
